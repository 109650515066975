<template>
  <div v-if="post" class="blog-post">
    <h2>{{ post.title }}</h2>
    <p>{{ post.date }}</p>
    <div v-html="htmlContent"></div>
  </div>
</template>

<script>
export default {
  name: 'BlogPost',
  data() {
    return {
      post: null,
      htmlContent: ''
    };
  },
  async created() {
    const postId = this.$route.params.id;
    try {
      const postResponse = await fetch(`/blog-posts/posts.json`);
      const posts = await postResponse.json();
      this.post = posts.find(post => post.id === postId);

      if (this.post) {
        const contentResponse = await fetch(this.post.contentUrl);
        this.htmlContent = await contentResponse.text();
      }
    } catch (error) {
      console.error('Error fetching post:', error);
    }
  }
};
</script>

<style scoped>
.blog-post {
  font-family: 'Montserrat', sans-serif; /* Use Google Fonts */
  max-width: 800px;
  margin: 2rem auto;
  padding: 1rem;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.blog-post h2 {
  margin-bottom: 1rem;
  text-align: center;
}

.blog-post p {
  margin-bottom: 1.5rem;
}

.inline-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1rem 0;
}
</style>