<template>
  <section id="contact">
    <div class="form-container">
      <form @submit.prevent="submitForm">
        <label for="name">Name:</label>
        <input type="text" id="name" v-model="name" required>

        <label for="email">Email:</label>
        <input type="email" id="email" v-model="email" required>

        <label for="message">Message:</label>
        <textarea id="message" v-model="message" @input="limitText" required></textarea>
        <p class="char-count">{{ message.length }} / 1000</p>

        <button type="submit">Send</button>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactForm',
  data() {
    return {
      name: '',
      email: '',
      message: ''
    };
  },
  methods: {
    limitText() {
      if (this.message.length > 1000) {
        this.message = this.message.slice(0, 1000);
      }
    },
    async submitForm() {
      const formData = {
        name: this.name,
        email: this.email,
        text: this.message
      };

      try {
        const response = await fetch('https://ivanrozhkov-be-5h6aune3ra-ue.a.run.app/api/v1/contact_form', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        });

        if (response.ok) {
          alert('Message sent successfully!');
        } else {
          alert('Failed to send message.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred while sending the message.');
      }
    }
  }
};
</script>

<style scoped>
section {
  max-width: 800px;
  margin: 2rem auto;
  padding: 1rem;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container {
  max-width: 600px;
  margin: 0 auto;
}

form label {
  display: block;
  margin: 0.5rem 0 0.2rem;
}

form input, form textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 3px;
}

form textarea {
  resize: vertical;
  min-height: 100px;
  max-height: 300px;
}

.char-count {
  font-size: 0.9rem;
  color: #666;
  text-align: right;
}

form button {
  padding: 0.7rem 1.5rem;
  background: #333;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

form button:hover {
  background: #555;
}
</style>