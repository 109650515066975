<template>
  <div class="blog-list">
    <ul>
      <router-link
        v-for="(post, index) in posts"
        :key="post.id"
        :to="'/blog/' + post.id"
      >
        <li :class="getBackgroundClass(index)">
          <h2>{{ post.title }}</h2>
          <p>{{ post.date }}</p>
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'BlogList',
  data() {
    return {
      posts: []
    };
  },
  methods: {
    async fetchPosts() {
      try {
        const response = await fetch('/blog-posts/posts.json');
        const data = await response.json();
        // Sort posts after fetching
        this.posts = data.sort((a, b) => new Date(b.date) - new Date(a.date));
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    },
    getBackgroundClass(index) {
      return index % 2 === 0 ? 'bg-white' : 'bg-light-gray';
    }
  },
  created() {
    this.fetchPosts();
  }
};
</script>

<style scoped>
.blog-list {
  margin-bottom: 1.5rem;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  max-width: 800px;
  margin: 2rem auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  background-color: #f9f9f9;
}

.blog-list li:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.blog-list h2 {
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.blog-list p {
  font-size: 1rem;
  color: #666;
}

.blog-list ul {
  list-style: none;
  padding: 0;
}

.blog-list li {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.blog-list li:last-child {
  border-bottom: none;
}

.blog-list a {
  color: #007BFF;
  text-decoration: none;
  font-weight: 600;
}

.bg-white {
  background: #fff;
}

.bg-light-gray {
  background: #f4f4f4;
}
</style>