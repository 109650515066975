export function addLogos() {
    const logos = [
    `/logos/artezio.png`,   // Absolute paths starting from the root
    `/logos/auriga_1.png`,
    `/logos/exness_1.png`,
    `/logos/exness_2.png`,
    `/logos/mera_1.png`,
    `/logos/rn_1.png`,
    `/logos/rn_2.png`,
    `/logos/tabby_1.png`,
    `/logos/tabby_2.webp`
    ];
    let logosIterator = new Set(shuffleArray(logos)).values();

    const container = document.querySelector('.logo-pattern-background');

    if (container == null) {
        return;
    }

    const rows = 4;
    const cols = 4;
    const cellWidth = 100 / cols;  // Percentage width of each cell
    const cellHeight = 100 / rows; // Percentage height of each cell

    // Create 4x4 grid
    for (let row = 0; row < rows; row++) {
        for (let col = 0; col < cols; col++) {
        const logo = document.createElement('div');
        logo.classList.add('logo');
        const img = document.createElement('img');
        img.classList.add('logo-pattern-background-img');

        let logoName = logosIterator.next()
        if (logoName.done) {
            logosIterator = new Set(shuffleArray(logos)).values();
            logoName = logosIterator.next()
        }

        img.src = logoName.value;

        img.style.pointerEvents = 'none'; // Disable interaction with the logos
        logo.appendChild(img);

        // Set the position of each logo in its respective grid cell
        logo.style.position = 'absolute';
        logo.style.top = `${row * cellHeight + Math.random() * (cellHeight / 2)}%`; // Randomly within the cell
        logo.style.left = `${col * cellWidth + Math.random() * (cellWidth / 2)}%`;  // Randomly within the cell
        logo.style.transform = `rotate(${Math.random() * 60 - 30}deg)`;  // Rotate between -30 and 30 degrees

        container.appendChild(logo);
        }
    }
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
